.tooltip-credit-note {
  color: red !important;
  position: relative;
  display: inline-block;
  .tooltip-credit-note-content {
    visibility: hidden;
    position: absolute;
    z-index: 1;
  }
  &:hover .tooltip-credit-note-content {
    animation: fadeIn 0.5s;
    width: 200px;
    background: #333333;
    visibility: visible;
    top: -60px;
    left: 60px;
    border-radius: 10px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .tooltip-credit-note {
    &:hover .tooltip-credit-note-content {
      animation: fadeIn 0.5s;
      width: 200px;
      background: #333333;
      visibility: visible;
      top: 40px;
      left: -50px;

      border-radius: 10px;
    }
  }
}

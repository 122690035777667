#aged-payables-page{
    .aged-payable-cell-color{
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-top: solid 1px var(--ion-color-dark);
        background: rgba(var(--ion-color-secondary-rgb), 0.14);
    }
    .aged-payable-table-title{
        overflow:scroll;
        overflow-x:hidden;
        p{
            width: 600px;
            margin: 0 0 0 305px;
            text-align: center;
            font-weight: bold;
        }
    }
}
.signup-success_modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 103;
  top: 0;
  left: 0;
}

.signup-success_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 650px;
  height: 480px;
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  animation: fade-in 0.35s ease-in-out forwards;
  @media (max-width: 750px) {
    width: 580px;
  }
  @media (max-width: 650px) {
    width: 400px;
    padding: 2em;
    //height: 300px;
  }
  @media (max-width: 460px) {
    width: 320px;
    padding: 2em;
    //height: 300px;
  }
  .logo {
    width: 230px;
    margin-bottom: 35px;
  }
  .title {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #262626;
  }
  .sub-title {
    margin: 0;
    padding: 0;
    max-width: 420px;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #6f6f6f;
    margin: 24px 0;
  }
}

// fade-in animation
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-15%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

#wrapper {
    &.content {
        display: flex;
        padding: 32px;
        justify-content: center;
        
        .wrapper {
            width: 100%;
            height: 100%;
        }
    }
}

@media (min-width: 576px) {
    #wrapper {
        &.content {
            .wrapper {
                height: 100%;
                width: 540px;
            }
        }
    }
}

@media (min-width: 768px) {
    #wrapper {

        &.content {
            .wrapper {
                height: 100%;
                width: 720px;
            }
        }
    }
}

@media (min-width: 992px) {
    #wrapper {
        &.content {
            .wrapper {
                width: 720px;
                height: 100%;
            }
            .xl {
                width: 920px;
                height: 100%;
            }
        }
    }
}

@media (min-width: 1200px) {
    #wrapper {

        &.content {
            .wrapper {
                width: 720px;
                height: 100%;

            }
            .xl {
                width: 1080px;
                height: 100%;
            }
        }
    }
}
ion-segment {
    border-bottom: 1px solid var(--input-background);
    --background: #ffffff;
    ion-badge {
        background-color: var(--ion-color-medium);
    }
    .badge {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background-color: var(--ion-color-medium);
        position: relative;
        color: var(--ion-color-primary-contrast);
        border-radius: 50%;
        margin-left: 5px;
        font-size: 10px;
    }

    ion-label {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        text-transform: none;
    }

    ion-segment-button {
        &.selected{
            font-weight: 600;
        }
        --indicator-height: 4px;
            &::part(indicator){
            }
        &::part(indicator-background){
            border-radius: 24px;
        }
        
        
        
    }

    .segment-button-checked {
        .badge {
            background-color: var(--ion-color-primary);
        }
    }

    
    &::-webkit-scrollbar-thumb {
        --tw-bg-opacity: 0;
        opacity: 0;
        background-color: rgba(82, 82, 82, var(--tw-bg-opacity));
    }
}

.sfn-warning-payroll-modal{
    .modal-wrapper{
        height:30%;
        width: 70%;
        border-radius: 8px;
    }
    @media (min-width: 768px) {
        .modal-wrapper{
            width: 360px;
            border-radius: 8px;
            min-height: 200px;
            max-height: 160px;
            height: 100%;
            .ion-page {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    
}
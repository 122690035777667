.customization-content {
    .file-btn {
        input[type="file"] {
          display: none;
        }
        .custom-file-upload {
          border-radius: 64px;
          background: none;
          height: 46px;
          display: inline-block;
          padding: 6px 22px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--ion-color-primary);
          font-size: 14px;
          border: 2px solid var(--ion-color-primary);
          &:hover {
            background: rgba(var(--ion-color-primary-rgb), 0.04);
          }
        }
      }
      
}
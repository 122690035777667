.login-container{
    background: url('./background-closeup.jpg');
    width: 100vw;
    margin: 0px;
    height: 100vh; 
    background-size: cover;
}

.box{
    max-width: 30%;
    padding: 16px;
    display: flex;
    margin-left: auto;
    height: 100%;
    align-items: center;
}

.login-wrapper{
    background: var(--ion-color-light);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
    form {
        width: 80%;
        padding: 16px;
    }

    .sfn-registry {
        width: 80%;
        padding: 16px;
        color: white !important;
    }
}


@media (max-width: 1080px) {
    .box {
        max-width: 45%;
    }
}


@media (max-width: 768px) {
    .box {
        padding: 0;
        max-width: 100%;
    }
    .login-wrapper{
        border-radius: 0;
    }
}
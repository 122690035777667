.search-table {
  ion-icon {
    --ionicon-stroke-width: 40px;
    color: var(--ion-color-medium);
  }

  .input-search-table {
    z-index: 0;
    --padding-start: 0;
    --inner-padding-end: 0;
    --background: none;
    margin-top: 2px;
    font-size: 14px;
    ion-label {
      font-size: 16px !important;
      margin-bottom: 12px !important;
    }

    &.item-has-focus {
      ion-label {
        color: var(--ion-color-secondary) !important;
      }
      ion-input,
      ion-textarea {
        --background: var(--input-background);
      }
    }

    &:focus-within {
      ion-input,
      ion-textarea {
        border: 1px solid var(--ion-color-secondary);
      }
    }

    ion-input,
    ion-textarea {
      z-index: 0;
      --placeholder-color: var(--ion-color-medium);
      --padding-start: 8px !important;
      --padding-end: 8px;
      --background: var(--input-background-tint);
      &.no-bg {
        --background: none;
      }
      border-radius: 8px;
      min-height: 30px;

      &:hover {
        border: 0.5px solid var(--ion-color-secondary-shade);
      }
    }
  }
}

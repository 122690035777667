.sfn-upload {
  .button-upload {
    border-radius: 10px;
    width: 173px;
    height: 73px;

    input[type="file"] {
        display: none;
      }
  }
}

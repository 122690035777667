.grid-table-node {
    .donut {
        width: 4rem;
        height: 4rem;
        margin: 4rem;
        border-radius: 50%;
        border: 0.3rem solid rgba(#d7d8da, 0.3);
        border-top-color: #d7d8da;
        animation: 1.5s spin infinite linear;
      
        &.multi {
          border-bottom-color: #d7d8da;
        }
      }

      .content {
          height: 60vh;
      }
}
.sfn-user-avatar {
  background: var(--ion-color-tertiary);
  color: #ffffff;
  width: 32px;
  height: 32px;
  margin: 0 8px 0 3px;
  ion-img {
    &::part(image) {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
  }
}

.sfn-user-table-pending {
  color: #f2994a;
}

.sfn-tooltip {
    position: relative;
    display: inline-block;

    .sfn-tooltip-content {
      visibility: hidden;
      position: relative;
      z-index: 10;
    }
    &:hover .sfn-tooltip-content {
      animation: fadeIn 0.5s;
      width: 200px;
      background: #333333;
      visibility: visible;
      top: -80px;
      left: 60px;
      border-radius: 10px;
      padding: 10px;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    .snf-tooltip {
      &:hover .sfn-tooltip-content {
        animation: fadeIn 0.5s;
        width: 200px;
        background: #333333;
        visibility: visible;
        top: 40px;
        left: -50px;
  
        border-radius: 10px;
      }
    }
  }
  
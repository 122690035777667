#sfn-expense-generator {
    .expense-header{
        .business-logo{
            ion-img{
                width: 100px;
                height: 100px;
                object-fit: contain;
            }
        }
    }
    .summary{
        .totals{
            min-width: 100px;
            // text-align: right;
        }
    }
    .expense-footer{
        background: rgba(var(--ion-color-medium-rgb), 0.14);
    }
}
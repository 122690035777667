#sfn-cancel-subscription-modal {
    z-index: 11;
    @media (min-width: 768px) {
        .modal-wrapper{
            border-radius: 16px;
            min-height: 300px;
            height: auto;
            max-height: 640px;
            height: 100%;
            width: 400px;

            .ion-page {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
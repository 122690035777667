#contentEmployee {
    overflow: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
   .content {
       scroll-behavior: auto;
       overflow-y: scroll;
       height: 100vh;
       overflow-x: hidden;
   }
   // ::-webkit-scrollbar { 
   //     display: none;  /* Safari and Chrome */
   // }
   .w-medium {
       height: 450px;
   }
}
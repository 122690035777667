.kpi-card {
    ion-icon {
        color: var(--ion-color-secondary)
    }
    &.small{
        .text-section{
            h3{
                font-size: 16px !important;
            }
            p{
                font-size: 14px !important;
                font-weight: 400;
                margin-top: -8px;
            }
        }
    }
}
.sfn-input-date{
    ion-label {
        width: 100%;
        white-space: normal !important;
    }
    display: flex;
    align-items: flex-end;
    ion-icon{
        color: var(--ion-color-medium);
        margin-right: 12px;
    }
    input[type="date"]{
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator { 
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
     }
}
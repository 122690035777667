.sfn-dasboard {
  .sfn-dasboard-flat {
    table.snf-table {
      width: 100% !important;
      margin: 0 !important;
    }
  }

  table.snf-table {
    width: 100% !important;
    margin: 0 !important;
  }
}

    .sfn-header{
            display: flex;
            height: 72px;
            // padding-right: 16px;
            background: var(--ion-color-light);
            z-index: 1;
        .title{
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-right: auto;
            z-index: 1;
            h3{
                color: var(--ion-color-primary);
                font-weight: 800;
            }
            span{
                margin-top: 8px;
                color: var(--ion-color-medium);
                
            }
        }
    }

    @media (max-width: 992px) {
        .sfn-header {
         
      
          .gridia-menu-button {
            display: none;
            width: 200px !important;
            background-size: contain;
          }
        }
      }
.sfn-input{
    z-index: 0;
    --padding-start: 0;
    --inner-padding-end: 0;
    --background: none;
    margin-top: 2px;
    font-size: 14px;
    ion-label {
        font-size: 16px !important;
        margin-bottom: 12px !important;
        text-overflow: clip !important;
        white-space: inherit !important;
    }

    &.item-has-focus  {
        ion-label{
            color: var(--ion-color-secondary) !important;
        }
        ion-input,
        ion-textarea{
            --background: var(--input-background);
         }
    }

    &:focus-within {
        ion-input,
        ion-textarea {
        border: 1px solid var(--ion-color-secondary);
        }
    }

    ion-input,
    ion-textarea{
        z-index: 0;
        --placeholder-color: var(--ion-color-medium);
        --padding-start: 8px !important;
        --padding-end: 8px;
        --background: var(--input-background-tint);
        &.no-bg{
        --background: none; 
        }
        border-radius: 4px;
        min-height: 44px;

        &:hover{
            border: 0.5px solid var(--ion-color-secondary-shade); 
        }

        textarea {
            min-height: 44px !important;
        }
    }

    
}


//selects
.sfn-select {
    height: 40px
}

//slides 
.sfn-slides {
    ion-slide {
        display: flex;
        flex-direction: column;
        height: 100vh;
        form {
            width: 100%;
        }
    }

    &.medium {
        height: 450px;
    }

}

//checkbox 
.sfn-checkbox {
    --checkmark-width: 2px;
    --border-radius: 5px;
    --background: var(--input-background-tint);
    --background-checked:  var(--ion-color-secondary);
    --border-color: var(--input-background-tint);
    --border-color-checked: var(--ion-color-secondary);
}

.sfn-checkbox-table {
    .container {
        display: block;
        position: relative;
        // margin-bottom: 12px;
        padding-left: 25px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color:  var(--input-background-tint);
        border-radius: 5px;
        border: 1.3px solid var(--ion-color-medium);
      }
      
      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color:  var(--input-background-tint);
      }
      
      /* When the checkbox is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background-color: var(--ion-color-secondary);
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        // position: absolute;
        display: none;
        align-items: center;
        justify-content: center;
      }
      
      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .container .checkmark:after {
        // left: 6px;
        // top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
}

.sfn-input-validate {
    ion-label{
        color: var(--ion-color-danger) !important;
    }
}
.accordion {
  width: 100%;
  // padding: 10px;
  // margin: 4px 4px;
  z-index: 0;
 
  .accordion-header {
    width: 100%;
    // display: block;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: flex-start;
    z-index: 0;
    position: relative;
    padding-bottom: 10px;

    ion-icon {
      transition: transform 0.3s ease-in-out;
      font-weight: 600;
    }
  }

  .divider {
    // border-bottom: 2px solid var(--ion-color-medium-tint);
  }
  .accordion-item {
    z-index: 0;
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  }

  .accordion-item.collapsed {
    max-height: 0;
    z-index: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .accordion-content {
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 0;
  }
}

#profile-avatar {
  .content {
    background: var(--input-background);
    width: 190px;
    height: 40px;
    p {
      color: var(--ion-color-dark);
    }
    .circle-avatar {
      background: var(--ion-color-tertiary);
      color: #ffffff;
      width: 36px;
      height: 36px;
      padding: 10px;
      margin: 0 8px 0 0px;
      ion-img {
        &::part(image) {
          border-radius: 50%;
          width: 36px;
          height: 36px;
          object-fit: cover;
        }
      }
    }
  }
  .dropdown {
    background: var(--ion-color-light);
    border: 1px solid var(--input-background);
    position: absolute;
    right: 16px;
    width: 300px;
    min-height: 300px;
  }
}

@media (max-width: 576px) {
  #profile-avatar {
    .content {
      p {
        display: none;
      }
      width: 40px;
      .circle-avatar {
        margin: 0;
        width: 40px;
        height: 40px;
      }
    }
  }
}

ion-menu {
  max-width: 240px;
  border-right: 1px solid var(--input-background);

  .header-title {
    h3 {
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // width: 140px;
    }
  }
  ion-item {
    --background: none;
    // --background-hover: var(--ion-color-medium);
    &:hover {
      --background: rgba(var(--ion-color-medium-rgb), 0.1);
    }
    border-radius: 26px;
    margin: 4px;
    cursor: pointer;
    &.selected {
      --background: rgba(var(--ion-color-primary-rgb), 0.14);
      ion-label {
        color: var(--ion-color-primary) !important;
        font-weight: bold;
      }
      ion-icon {
        color: var(--ion-color-primary);
      }
    }
    ion-icon {
      margin: 0 12px 0 8px;
      font-size: 16px;
    }
    ion-label {
      color: var(--ion-color-medium);
      font-weight: 400;
      font-size: 14px !important;
    }
  }

  .line {
    border-bottom: 1px solid var(--ion-color-light-shade);
    width: 60%;
  }
}

@media (max-width: 992px) {
  ion-menu {
    max-width: none;
  }
}

.split-pane-visible > .split-pane-side {
  min-width: var(--width-menu, 80px) !important;
  max-width: var(--width-menu, 80px) !important;
  transition: all 0.35s ease-out;
  .logo {
    // display: var(--logo-display, flex);
    width: 120px;
  }
  .isotipo {
    margin-right: 4px;
    width: 20px !important;
    // height: 70px;
  }
  // .enterprise {
  //   display: var(--enterprise-display, flex);
  // }
  ion-item {
    --background: none;
    width: auto;
    // --background-hover: var(--ion-color-medium);
    &:hover {
      --background: rgba(var(--ion-color-medium-rgb), 0.1);
    }
    border-radius: 26px;
    margin: 4px;
    cursor: pointer;
    &.selected {
      --background: rgba(var(--ion-color-primary-rgb), 0.14);
      ion-label {
        color: var(--ion-color-primary) !important;
        font-weight: bold;
      }
      ion-icon {
        color: var(--ion-color-primary);
      }
    }
    ion-icon {
      margin: 0 12px 0 8px;
      font-size: 16px;
    }
    ion-label {
      display: var(--label-display, flex);
      color: var(--ion-color-medium);
      font-weight: 400;
      font-size: 14px !important;
    }
  }
}

.symfonia-sub-menu {
  .symfonia-sub-content{
    min-width: 240px;
    width: 240px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    
    ion-item {
      --background: none;
      // --background-hover: var(--ion-color-medium);
      &:hover {
        --background: rgba(var(--ion-color-medium-rgb), 0.1);
      }
      border-radius: 26px;
      margin: 4px;
      cursor: pointer;
      &.selected {
        --background: rgba(var(--ion-color-primary-rgb), 0.14);
        ion-label {
          color: var(--ion-color-primary) !important;
          font-weight: bold;
        }
        ion-icon {
          color: var(--ion-color-primary);
        }
      }
      ion-icon {
        margin: 0 12px 0 8px;
        font-size: 16px;
      }
      ion-label {
        color: var(--ion-color-medium);
        font-weight: 400;
        font-size: 14px !important;
      }
    }
  }
}

#logContent {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;

  ion-icon {
    font-size: 24px;
  }

  .loger {
    grid-column: 1 / -1;
  }
  .list {
    border-radius: 8px 8px;
    display: flex;
    flex-direction: column;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    padding: 5px;
    overflow: auto;
    height: auto;
    max-height: 400px;
  }
  .list p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .item {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    border-radius: 8px 8px;
  }

  .item:hover {
    background: #fafafa;
  }

  .item p {
    width: 100%;
    margin: 16px 5px 16px 5px;
  }
  .actions {
    display: flex;
    flex-direction: row;
  }

  .progress {
    width: 250px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ion-icon {
      font-size: 24px;
    }
  }

  .progress-bar::part(indicator) {
    background-color: var(--primary, blue);
  }
}

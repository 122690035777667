#profile-avatar-image {
  width: 100%;
  .avatar-image {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    ion-img {
      &::part(image) {
        border-radius: 50%;
        width: 70px;
        height: 70px;
        object-fit: cover;
      }
    }
    .loader {
      border-radius: 50%;
      width: 70px;
      height: 70px;
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: var(--ion-color-medium-tint);
    }
    ion-button {
      width: 32px;
      height: 32px;
      --padding-bottom: 8px;
      --padding-end: 8px;
      --padding-start: 8px;
      --padding-top: 8px;
      --border-radius: 50%;
      position: absolute;
      right: 108px;
      top: 48px;
      input[type="file"] {
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        opacity: 0;
        cursor: inherit;
      }
    }
  }
}

//modals
.sfn-modal{
    &::part(content){
        height: 100%;
        width: 100%;
    }
}
.sfn-modal-small{
    @media (min-width: 768px) {
        &::part(content){
            border-radius: 8px;
            // min-height: 90vh;
            width: 400px;
            max-height: 700px;
            height: 100%;
            // max-height: 800px;
        }
    }
}
.sfn-modal-medium{
    @media (min-width: 768px) {
        &::part(content){
            border-radius: 16px;
            min-height: 200px;
            max-height: 500px;
            height: 100%;
            .ion-page {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    
}

.sfn-modal-medium-height{
    @media (min-width: 768px) {
        &::part(content){
            border-radius: 16px;
            min-height: 200px;
            max-height: 620px;
            height: 100%;
            .ion-page {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    
}



//buttons
    ion-button{
        &.sfn-button{
        text-transform: none;
        --box-shadow: none;
        height: 46px;
        }
        &.sfn-button-rounder-full{
            height: 36px;
            width: 36px;
            --padding-start: 0;
            --padding-end: 0;
        }
}

.button-disabled{
    &:disabled {
        opacity: 0.3;
    }
}

button{
    &.sfn-form-button{
        height: 46px;
        text-transform: none;
        border-radius: 46px;  
        padding: 8px 24px 8px 24px;
        font-weight: 500;
        &.primary{
            background: var(--ion-color-primary);
            color: var(--ion-color-primary-contrast);
            &:hover{
                background: rgba(var(--ion-color-primary-rgb), 0.9);
                
            }
        }
        &.danger{
            background: var(--ion-color-danger);
            color: var(--ion-color-danger-contrast);
            &:hover{
                background: rgba(var(--ion-color-danger-rgb), 0.9);
                
            }
        }
    }
}

//ioncontent padding
ion-content.sfn-content{
    --padding-bottom: 32px;
    --padding-end: 3rem;
    --padding-start: 3rem;
    --padding-top: 16px;
}

// talbe
.sfn-data-table {
    &.sfn-data-table-overflow-none {
        &.rgt-wrapper {
            .rgt-container {
                overflow: hidden !important;
            }
        }
    }
    &.rgt-wrapper{
        border: none;
        min-height: -webkit-fill-available; 
        // height: 535px;
    }
    &.sfn-data-table-xs{
        &.rgt-wrapper{
            min-height: 280px !important;
            height: auto;
        }
    }
    &.sfn-data-table-sm{
        &.rgt-wrapper{
            min-height: 420px !important;
            height: auto;
        }
    }
    &.sfn-data-table-slim{
        &.rgt-wrapper{
            min-height: auto !important;
            height: auto;
        }
    }
    .rgt-search-input{
        border-radius: 8px;
        margin: 4px;
        padding: 4px 8px;
        background: var(--input-background-tint);
        &:focus{
            background: var(--input-background);
            border: 1px solid var(--ion-color-secondary);
        }
    }
    .rgt-cell-header-sticky{
        color: #000000;
        font-weight: 600;
        border-bottom: 1px solid #E0E0E0;
        font-size: 16px;
    }
    .rgt-cell-header-inner{
        padding: 0  4px 0 10px !important;
    }
    &.no-cell-padding{
        .rgt-cell-header-inner{
            padding: 0 !important;
        }
    }
    .rgt-cell-inner{
        margin: 0 10px !important;
    }
    .rgt-header-container{
        background: var(--ion-color-light);
    }
    
    .rgt-row-hover{
        cursor: pointer;
        background: var(--input-background-tint) !important;
    }

    .rgt-row-odd{
        background: var(--ion-color-light);        
    }
    .rgt-row-even{
        background: var(--input-background);
    }
    .rgt-footer{
        background: var(--ion-color-light);

    }
}


//popover
.sfn-table-popover{
    .popover-content{
        padding: 8px;
    }
}

//alert dialog 
.sfn-alert-dialog{
    .alert-wrapper{
        border-radius: 16px;
        min-width: 360px;
        padding: 8px;
        .alert-head{
            h2{
                &.alert-title{
                    font-size: 18px;
                }
                &.alert-sub-title{
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 24px;
                    white-space: pre-line;
                    -webkit-user-select: text; /* Safari */
                    -ms-user-select: text; /* IE 10 and IE 11 */
                    user-select: text; /* Standard syntax */
                }
            }
        }
        .alert-message{
            font-size: 14px;
            font-weight: 600;
            color: var(--ion-color-dark);
        }
        .alert-button-group{
            button{
                height: 46px;
                background: var(--ion-color-primary);
                color: var(--ion-color-light);
                text-transform: none;
                border-radius: 26px;
                padding: 20px;
                &.secondary{
                    color: var(--ion-color-medium);
                    background: none;
                    &:hover{
                        background: rgba(var(--ion-color-medium-rgb), 0.14);
                    }
                } 
                &.delete{
                    background: var(--ion-color-danger);
                    color: var(--ion-color-light);
                }
            }
        }
    }
}

.modal-close-button{
    background: rgba(var(--ion-color-medium-rgb), 0.2);
    border-radius: 50%;
    padding: 4px;
    font-size: 25px !important;
    &:hover{
        background: rgba(var(--ion-color-medium-rgb), 0.5);
    }
}

//status colors
.OVERDUE, .REJECTED{
    color: var(--ion-color-danger);
    &.with-bg{
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        background: rgba(var(--ion-color-danger-rgb), 0.14);
        margin: 4px;
        padding: 4px;
    }
}
.PAID, .ACCEPTED{
    color: var(--ion-color-success);
    &.with-bg{
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        background: rgba(var(--ion-color-success-rgb), 0.14);
        margin: 4px;
        padding: 4px;
    }
}
.UNPAID{
    color: var(--ion-color-tertiary);
    &.with-bg{
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        background: rgba(var(--ion-color-tertiary-rgb), 0.14);
        margin: 4px;
        padding: 4px;
    }
}

.PARTIAL{
    color: var(--ion-color-warning);
    &.with-bg{
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        background: rgba(var(--ion-color-warning-rgb), 0.14);
        margin: 4px;
        padding: 4px;
    }
}

.CANCELLED{
    color: var(--ion-color-medium);
    &.with-bg{
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        background: rgba(var(--ion-color-medium-rgb), 0.14);
        margin: 4px;
        padding: 4px;
    }
}

@media (max-width: 768px) {
    .sfn-modal-small{
        &::part(content){
            border-radius: 0px;
            width: 100%;
        }
    }
    .sfn-modal-medium{
        &::part(content){
            border-radius: 0px;
            width: 100%;
        }
    }
    .sfn-alert-dialog{
        .alert-wrapper{
            min-width: auto !important;
        }
    }
    ion-content.sfn-content{
        --padding-bottom: 32px;
        --padding-end: 16px;
        --padding-start: 16px;
        --padding-top: 16px;
    }
}

//circle-avatar detail
.sfn-circle-avatar {
    background: var(--ion-color-tertiary);
    color: #ffffff;
    width: 66px;
    height: 66px;
    margin: 0 8px 0 3px;

    ion-icon {
       font-size: 25px;
    }
}

.sfn-donut {
    width: 4rem;
    height: 4rem;
    margin: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(#d7d8da, 0.3);
    border-top-color: #d7d8da;
    animation: 1.5s spin infinite linear;
  
    &.multi {
      border-bottom-color: #d7d8da;
    }
  }

  .chat-content-only{
    display: contents!important;
  }
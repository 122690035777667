#help-header {
  .content {
    color: var(--ion-color-tertiary);
    ion-icon{
      // margin-top: 6px;
      // margin-right: 2rem;
      font-size: 2rem;
    }
    svg{
      color: #2F455C;
    }
    .circle-avatar {
      background: var(--ion-color-tertiary);
      color: #ffffff;
      width: 36px;
      height: 36px;
      margin: 0 8px 0 3px;
      ion-img {
        &::part(image) {
          border-radius: 50%;
          width: 36px;
          height: 36px;
          object-fit: cover;
        }
      }
    }
  }
  .dropdown {
    background: var(--ion-color-light);
    border: 1px solid var(--input-background);
    position: absolute;
    right: 16px;
    width: 300px;
    min-height: fit-content;
  }
}

@media (max-width: 576px) {
  #help-header {
    .content {
      p {
        display: none;
      }
      width: 40px;
      .circle-avatar {
        margin: 0;
        width: 40px;
        height: 40px;
      }
    }
  }
}

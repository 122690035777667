.input-date-payroll {
    .item:hover {
        background: var(--ion-color-light-tint);
    }

    .month-picker {
		width: 100%;
		grid-column: 1 / -1;
		display: grid;
		grid-gap: 5px;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(4, 1fr);
	}
	.month-element {
		width: 100%;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		user-select: none;
		transition: all 0.2s;
	}

    .selected {
        border-radius: 6px;
        color: var(--input-background);
		background-color: var(--ion-color-secondary);
	}
}

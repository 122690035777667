@mixin cta {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 900px) {
    display: none;
  }
}

.onboarding {
  &--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
    top: 0;
    left: 0;
    overflow-y: scroll;
  }
  &--card {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    display: flex;
    width: 960px;
    height: 480px;
    @media (max-width: 1100px) {
      width: 800px;
      height: 400px;
    }
    @media (max-width: 900px) {
      width: 600px;
      height: 300px;
    }
    @media (max-width: 670px) {
      //mobile
      margin: 4em 0;
      width: 500px;
      height: auto;
      flex-direction: column-reverse;
    }
    @media (max-width: 520px) {
      width: 380px;
    }
    @media (max-width: 430px) {
      width: 300px;
    }
    background: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    animation: fade-in 0.35s ease-in-out forwards;
  }
  &--text {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 60px 75px 60px 75px;
    @media (max-width: 1100px) {
      padding: 40px 40px 40px 75px;
    }
    @media (max-width: 900px) {
      padding: 30px 30px 30px 30px;
    }
    @media (max-width: 670px) {
      padding: 40px 40px 40px 40px;
    }
    box-sizing: border-box;
    width: 50%;
    @media (max-width: 670px) {
      width: 100%;
    }
    &--current__page--wrapper {
      display: flex;
      gap: 0.5em;
    }
    &--current__page {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #c4c4c4;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      @media (max-width: 670px) {
        width: 14px;
        height: 14px;
      }
      &--active {
        cursor: default;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: #c4c4c4;
        background: #0090ff !important;
        @media (max-width: 670px) {
          width: 14px;
          height: 14px;
        }
      }
    }
    &--prev__cta {
      @include cta();
      left: 2em;
      background: rgba(196, 196, 196, 0.3);
    }
    &--skip__wrapper {
      display: flex;
      justify-content: flex-end;
    }
    &--skip {
      display: flex;
      align-items: center;
      //font-family: Mulish;
      width: fit-content;
      text-align: right;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      color: #c4c4c4;
      &:hover {
        opacity: 0.7;
      }
    }
    &--container {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
    }
    &--title {
      max-width: 313px;
      font-family: Oxygen;
      font-weight: bold;
      font-size: 28px;
      line-height: 35px;
      color: #2f455c;
      margin-bottom: 0.6em;
      @media (max-width: 1100px) {
        font-size: 24px;
        line-height: 30px;
      }
      @media (max-width: 900px) {
        font-size: 16px;
        line-height: 18px;
        max-width: none;
      }
      @media (max-width: 670px) {
        font-size: 28px;
        line-height: 35px;
      }
      @media (max-width: 430px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &--description {
      max-width: 313px;
      font-family: Oxygen;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #6f6f6f;
      margin-bottom: 3em;
      @media (max-width: 1100px) {
        margin-bottom: 2em;
      }
      @media (max-width: 900px) {
        font-size: 12px;
        max-width: none;
      }
      @media (max-width: 670px) {
        font-size: 14px;
        margin-bottom: 3em;
      }
      @media (max-width: 430px) {
        margin-bottom: 2em;
      }
      a {
        color: #0090ff;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    &--cta__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &--cta {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      //font-family: Mulish;
      width: 148px;
      height: 42px;
      background: #0090ff;
      border-radius: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      @media (max-width: 900px) {
        font-size: 12px;
        width: 95px;
        height: 28px;
      }
      @media (max-width: 670px) {
        width: 148px;
        height: 42px;
        font-size: 16px;
      }
      &:hover {
        opacity: 0.85;
      }
    }
  }
  &--image {
    display: flex;
    position: relative;
    background-color: #ffffff;
    width: 50%;
    height: 100%;
    @media (max-width: 670px) {
      //mobile
      width: 100%;
      height: 500px;
    }
    @media (max-width: 520px) {
      height: 380px;
    }
    @media (max-width: 430px) {
      height: 300px;
    }
    &--figure {
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &--next__cta {
      @include cta();
      right: 2em;
      background: rgba(196, 196, 196, 0.3);
    }
  }
}

// fade-in animation
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-15%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/* Core CSS required for Ionic components to work properly */
@import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/react/css/normalize.css";
@import "@ionic/react/css/structure.css";
@import "@ionic/react/css/typography.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css");

@layer base {
  ::-webkit-scrollbar-track {
    @apply shadow;
    @apply bg-white;
  }

  ::-webkit-scrollbar {
    width: 6px;
    @apply bg-white;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-gray;
  }
}

@layer utilities {
  .body-tag {
    @apply text-base text-gray font-bold mb-2;
  }
}

@import "./forms.scss";
@import "./elements.scss";
// @import "./fonts.scss";
// @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

html {
  font-size: 14px;
}
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}


.sfn-detail-payroll-modal{
    @media (min-width: 768px) {
        .modal-wrapper{
            width: 660px;
            border-radius: 8px;
            min-height: 200px;
            max-height: 560px;
            height: 100%;
            .ion-page {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    
}
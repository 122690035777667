.input-card-number {
  .icon {
    position: absolute;
    right: 0;
    bottom: calc(100% - 95%);
    // svg {
    //   font-size: 20px;
    //   color: #757575;
    // }
    margin-right: 8px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: var(--item-hover);
    }
  }
}

.elipsis-users {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 70%;

  .circle-avatar {
    background: var(--ion-color-tertiary);
    color: #ffffff;
    width: 36px;
    height: 36px;
    margin-right: -10px;
  }

  .ellipsis {
    position: relative;
    top: 15px;
    min-height: 22px;
    width: 22px;
    height: 2px;
    font-size: 10px;
    background: var(--ion-color-medium);
    color: white;
    font-weight: 500;
    margin-right: 10px;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
  }
}

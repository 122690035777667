.toggle-content {
  label,
  .toggle {
    height: 46px;
    border-radius: 100px;
  }

  label {
    width: 100%;
    background-color: var(--input-background-tint);
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .toggle {
    position: absolute;
    width: 50%;
    background-color: var(--ion-color-medium-shade);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .names {
    font-weight: bold;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: space-around;
    user-select: none;
    align-items: center;
  }

  .dark {
    opacity: 0.5;
  }

  [type='checkbox'] {
    display: none;
  }

  .active {
    transform: translateX(100%);
    background-color: var(--ion-color-medium-shade);
  }

  .active-color-text {
    color: #fff;
  }
}

.option-item{
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    border-radius: 6px;
    color: var(--ion-color-dark);
    ion-icon{
        margin-right: 12px;
        font-size: 18px;
    }
    &:hover{
        color: var(--ion-color-secondary);
        background: rgba(var(--ion-color-secondary-rgb), 0.14);
    }

    &.danger{
        color: var(--ion-color-danger);
        &:hover{
            color: var(--ion-color-danger);
            background: rgba(var(--ion-color-danger-rgb), 0.14);
        } 
    }
}
.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
}
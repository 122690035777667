.breads {
    a,p, ion-icon {
        color: var(--ion-color-medium)
    }
    
    a{
        cursor: pointer;
    }

    a:hover, a:focus {
        color: var(--ion-color-primary)
    }
}